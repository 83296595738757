<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense v-if="this.$store.state.user.user">
        <v-list-item three-line class="py-3">
          <v-list-item-content>
            <v-list-item-subtitle class="py-1 subtitle-1">
              <b>{{ this.$store.state.user.user.name }}</b>
            </v-list-item-subtitle>
            <v-list-item-title class="py-1">
              <v-chip color="secondary" small>
                {{ this.$store.state.user.user.role }}
              </v-chip></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          v-for="item in validItemsMenu"
          :key="item.text"
          :to="item.to"
          active-class="secondary white--text"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-left">{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-left">Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title> A & M Software </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!-- Load Route -->
      <router-view />
    </v-main>

    <v-footer app absolute class="font-weight-medium">
      <v-col cols="12" class="text-center pa-0"
        >&copy; {{ new Date().getFullYear() }}</v-col
      >
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    drawerItems: [
      {
        text: "Inicio",
        to: "/",
        icon: "mdi-home",
        roles: ["*"],
      },
      {
        text: "Alquileres",
        to: "/loans",
        icon: "mdi-tshirt-crew",
        roles: ["*"],
      },
      {
        text: "Ventas",
        to: "/sales",
        icon: "mdi-cash-register",
        roles: ["*"],
      },
      {
        text: "Arreglos",
        to: "/arrangements",
        icon: "mdi-content-cut",
        roles: ["*"],
      },
      {
        text: "Pagos",
        to: "/payments",
        icon: "mdi-account-cash",
        roles: ["*"],
      },
      {
        text: "Categorias",
        to: "/categories",
        icon: "mdi-tag-multiple",
        roles: ["*"],
      },
      {
        text: "Productos",
        to: "/dresses",
        icon: "mdi-hanger",
        roles: ["*"],
      },
      {
        text: "Usuarios",
        to: "/users",
        icon: "mdi-account-group",
        roles: ["SUPERADMIN"],
      },
      {
        text: "Sedes",
        to: "/offices",
        icon: "mdi-office-building-marker",
        roles: ["SUPERADMIN"],
      },
      {
        text: "Empleados",
        to: "/employees",
        icon: "mdi-account-group",
        roles: ["SUPERADMIN"],
      },
    ],
  }),
  computed: {
    role() {
      return this.$store.state.user.user.role;
    },
    validItemsMenu() {
      var validItems = [];
      this.drawerItems.forEach((item) => {
        if (this.validateRole(item.roles)) {
          validItems.push(item);
        }
      });
      return validItems;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    validateRole(roles) {
      if (roles.indexOf("*") >= 0) {
        return true;
      }

      if (roles.indexOf(this.role) >= 0) {
        return true;
      }

      return false;
    },
  },
};
</script>
<style>
.v-main__wrap {
  padding: 20px;
}
</style>